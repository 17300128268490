<template>
  <div class="container mt-5 mb-md-4 py-5">
    <breadcrumb class="mb-3 mb-md-4 pt-md-3" :crumbs="crumbs"></breadcrumb>

    <!-- Page content -->
    <div class="row justify-content-center pb-sm-2 pt-3">
      <div class="col-lg-6">
        <h1 class="h2 text-center pb-4 mb-3">Welcome Back!</h1>

        <div class="bg-light rounded-3 py-4 px-md-4">

          <form action="post" @submit.prevent="login">
            <!-- Email -->
            <div class="mb-4">
              <div class="email-label-row">
               <div class="left">
                 <label class="form-label" for="email">Email <span class="text-danger">*</span></label>
               </div>
                <div class="right" v-if="lastEmail">
                  <small><a @click.prevent="forgetEmailLocalAndSaved" href="#">Change email</a></small>
                </div>
              </div>
              <input v-model="form.email" class="form-control form-control-lg" type="email" id="email"
                     :disabled="lastEmail"
                     placeholder="Enter your email address" required>
            </div>
            <!-- / Email -->

            <!-- Password -->
            <div class="mb-4">
              <label class="form-label" for="password">Password <span class="text-danger">*</span></label>
              <input v-model="form.password" class="form-control form-control-lg" type="password" id="password"
                     placeholder="Enter your password" required>
            </div>
            <!-- / Password -->

            <input type="submit" class="d-none"/>

            <div class="actions-wrapper">
              <div class="left">
                <a :class="{'disabled': isLoading}"
                   @click.prevent="login" class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#">
                  <spinner v-if="isLoading" class="spinner-border-sm me-2"></spinner>
                  <i v-else class="fi-lock fs-sm me-2"></i>
                  Login
                </a>
              </div>
              <div class="right">
                <a href="/forgot-password">Forgot password?</a>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
    <!-- / Page content -->
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Spinner from "../components/Spinner";
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {Breadcrumb, Spinner},
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Login',
          active: true
        }
      ],
      form: {
        email: '',
        password: ''
      }
    }
  },
  created() {
    this.form.email = this.lastEmail ? this.lastEmail : '';
  },
  methods: {
    ...mapActions('auth', {
      attemptLogin: 'attemptLogin',
      forgetEmail: 'forgetEmail'
    }),

    forgetEmailLocalAndSaved() {
      this.form.email = '';
      this.forgetEmail();
    },

    login() {
      this.attemptLogin({
        email: this.form.email,
        password: this.form.password,
        to: this.$route.query.to ? this.$route.query.to : '/'
      });
    }
  },
  computed: {
    ...mapGetters('auth', {
      isLoading: 'isLoading',
      lastEmail: 'lastEmail'
    })
  },
  setup() {

  },
}
</script>


<style scoped lang="scss">
.email-label-row {
  display: flex;
  .left {
    flex-grow: 1;
  }
  .right {
    flex-shrink: 1;
  }
}

.actions-wrapper {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>